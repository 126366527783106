<template>
    <div class="allCheckpoints">
        <div :id="'carousel'+closeLocation.id" class="carouselCheckpoint slide" data-bs-ride="carousel"
             v-if="objImages.length > 0">
            <div class="carousel-inner">
                <div class="carousel-item" v-for="{id, url} in objImages" :key="id" :class="{ 'active': id === 0 }">
                    <img :src="url"/>
                </div>
            </div>
            <ol class="carousel-indicators">
                <li :data-bs-target="'#carousel'+closeLocation.id" v-for="id in objImages" :key="id"
                    :data-bs-slide-to="id"
                    :class="{ 'active': id === 0 }"></li>
            </ol>
        </div>
        <div class="checkpointsData">
            <div class="row pb-3">
                <div class="col-8">
                    <div class="row pt-3">
                        <div class="col-12">
                            <span>{{ closeLocation.type.name }}</span>
                        </div>
                        <div class="col-12 pb-2">
                            <h5 class="pt-1"> {{ closeLocation.name }} </h5>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt"
                                 class="navIcon svg-inline--fa fa-map-marker-alt fa-w-12" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path fill="currentColor"
                                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                            </svg>
                            <span>Mjesto, Država</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 pt-3">
                    <a href="#" @click="addFavorite">
                         <svg v-bind:class="{show: isFavorite}" class="myList" xmlns="http://www.w3.org/2000/svg"
                              width="25" height="22" viewBox="0 0 25 22">
                             <defs>
                                 <clipPath id="clip-Like_-_off">
                                     <rect width="25" height="22"/>
                                 </clipPath>
                             </defs>
                             <g id="Like_-_off" data-name="Like - off" clip-path="url(#clip-Like_-_off)">
                                 <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                     <path id="Path_1274" data-name="Path 1274"
                                           d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717ZM336.937-59.5l9.127,9.073,9.321-9.078v-3.83l-4.2-2.6-4.441,4.25-1.372.011L340.8-65.919l-3.863,2.566Z"
                                           fill="#1aa1ed"/>
                                 </g>
                             </g>
                         </svg>

                         <svg :class="{show: !isFavorite}" class="myList" xmlns="http://www.w3.org/2000/svg"
                              width="25" height="22" viewBox="0 0 25 22">
                             <defs>
                                 <clipPath id="clip-Like_-_on">
                                     <rect width="25" height="22"/>
                                 </clipPath>
                             </defs>
                             <g id="Like_-_on" data-name="Like - on" clip-path="url(#clip-Like_-_on)">
                                 <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                     <path id="Path_1274" data-name="Path 1274"
                                           d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717Z"
                                           fill="#1aa1ed"/>
                                 </g>
                             </g>
                         </svg>
                         </a>
                         <p class="text-center pt-1">My List</p>
                    <button @click="openLocation" class="btn btn-outline-secondary seeAll">See all</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="inlineButtons">
                        <a href="tel:{{'closeLocation.phone'}}" class="infoButton">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="13"
                                 height="12" viewBox="0 0 13 12">
                                <defs>
                                    <clipPath id="clip-Call">
                                        <rect width="13" height="12"/>
                                    </clipPath>
                                </defs>
                                <g id="Call" clip-path="url(#clip-Call)">
                                    <g id="phone" transform="translate(0.929 0.461)">
                                        <path id="Path_972" data-name="Path 972"
                                              d="M13.071,7.69V4c0,.013-.275,0-4.305,0,.007.018.028,3.684,0,3.69h1.845a4.92,4.92,0,0,1-4.92,4.92V10.765c-.008.01-3.676-.006-3.69,0,.013-.013-.013,4.271,0,4.305-.018-.02,3.673-.026,3.69,0A7.381,7.381,0,0,0,13.071,7.69Z"
                                              transform="translate(-2 -4)" fill="#707070"/>
                                    </g>
                                </g>
                            </svg>

                            Call
                        </a>
                        <a href="mailto:{{'closeLocation.email'}}" class="infoButton">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="18"
                                 height="13" viewBox="0 0 18 13">
                                <defs>
                                    <clipPath id="clip-Email">
                                        <rect width="18" height="13"/>
                                    </clipPath>
                                </defs>
                                <g id="Email" clip-path="url(#clip-Email)">
                                    <path id="Icon_zocial-email" data-name="Icon zocial-email"
                                          d="M.072,14.094V5.042q0-.016.047-.3L5.446,9.3.135,14.408a1.332,1.332,0,0,1-.063-.314ZM.779,4.115a.677.677,0,0,1,.267-.047H15.393a.89.89,0,0,1,.283.047L10.333,8.688l-.707.566L8.228,10.4l-1.4-1.147-.707-.566ZM.795,15.021,6.153,9.882l2.074,1.681L10.3,9.882l5.359,5.139a.754.754,0,0,1-.267.047H1.046a.712.712,0,0,1-.251-.047ZM11.009,9.3l5.311-4.557a.938.938,0,0,1,.047.3v9.051a1.2,1.2,0,0,1-.047.314Z"
                                          transform="translate(0.928 -3.068)" fill="#707070"/>
                                </g>
                            </svg>

                            Email
                        </a>
                        <a :href="directionLink" class="infoButton" target="_blank" @click="calculateRoute">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="18"
                                 height="17" viewBox="0 0 18 17">
                                <defs>
                                    <clipPath id="clip-Directions">
                                        <rect width="18" height="17"/>
                                    </clipPath>
                                </defs>
                                <g id="Directions" clip-path="url(#clip-Directions)">
                                    <path id="Path_1275" data-name="Path 1275"
                                          d="M9.881,1.707c.047-.059-7.98,7.985-7.99,8.049-.015.009,7.981,8.063,7.99,8.148-.052.044,7.968-8.157,7.93-8.148Zm1.636,10.2V9.928H8.345v2.379H6.759V9.135a.8.8,0,0,1,.793-.793h3.965V6.359l2.776,2.776Z"
                                          transform="translate(-0.997 -0.998)" fill="#707070"/>
                                </g>
                            </svg>

                            Directions
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="closeLocation.description">
                        {{ closeLocation.description.substring(0, 255) + "..." }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            objImages: [],
            idName: "",
            showModal: false,

            checkpoints: [],
            filledIcon: false,
            directionLink: "",
        }
    },
    props: {
        closeLocation: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isFavorite() {
            return this.$store.getters.isCheckpointInList(this.closeLocation.id);
        },
        userLocation() {
            return this.$store.getters.getLocation;
        }
    },
    mounted() {
        if (this.closeLocation.images.length > 0) {
            for (let i = 0; i < this.closeLocation.images.length; i++) {
                this.objImages[i] = {
                    id: i,
                    url: this.closeLocation.images[i].url
                }
            }
        }

        this.checkpoints = this.$store.getters.getCheckpointList;

        for (let i = 0; i < this.checkpoints; i++) {
            if (this.idItem === this.checkpoints[i]) {
                this.filledIcon = true;
                return;
            }
        }
    },
    methods: {
        calculateRoute() {
            this.isCalculateClicked = !this.isCalculateClicked;
            const locationLat = this.userLocation.lat;
            const locationLong = this.userLocation.lng;
            if (this.isCalculateClicked === true) {
                this.directionLink = "https://www.google.com/maps/dir/?api=1&";
                this.origin = "origin=" + locationLat + "+" + locationLong;
                this.destinationPoint = "&destination=" + this.closeLocation.lat + "+" + this.closeLocation.long;
                this.travelMode = "&travelmode=driving";
                this.directionLink = this.directionLink + this.origin + this.destinationPoint + this.travelMode;
            }
        },
        addFavorite() {
            if (this.isFavorite) {
                this.$store.dispatch('removeCheckpointList', this.closeLocation.id);
                return;
            }

            this.$store.dispatch('setCheckpointList', this.closeLocation.id);
        },
        openLocation() {
            this.$emit('openLocation', this.closeLocation.id);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.allCheckpoints {
    box-shadow: 3px 3px 6px #e8e8e8;

    .carouselCheckpoint {
        width: 100%;
        height: 160px;
        //padding: 0;

        @media screen and (min-width: 768px) {
            height: 240px;
        }

        .carousel-indicators {
            li {
                width: 8px;
                height: 8px;
                margin-bottom: -16px;
            }
        }

        .carousel-item {
            img {
                width: 100%;
                max-height: 160px;
                min-height: 160px;
                object-fit: cover;

                @media screen and (min-width: 768px) {
                    max-height: 200px;
                    height: 200px;
                }
            }
        }

    }

    .checkpointsData {
        margin-left: 10px;
        height: 100%;
        padding-bottom: 10px;
        margin-right: 10px;

        .seeAll {
            padding: 0px 0px;
            font-size: 16px;
            width: 100%;
            margin-top: 0px;

            &:hover, &:active {
                color: $secondary;
                background-color: white;
                border: 2px solid $secondary;
            }
        }
    }

    @media screen and (min-width: 768px) {
        max-height: 100%;
    }

    span {
        margin-right: 14px;
        color: #bebebe;
        font-size: 14px;
    }

    .myList {
        width: 30px;
        height: auto;
        color: #00A1ED;
        display: block;
        margin: auto;
    }

    .myList.show {
        display: none;
    }

    .textList {
        display: block;
        margin: auto;
        text-align: center;
        color: #707070;
    }

    .navIcon {
        width: 12px;
        height: auto;
        fill: #bebebe;
        color: #bebebe;
        margin-right: 10px;
    }

    a {
        text-decoration: none;
        color: #707070;

        + p {
            display: block;
            margin: auto;
            text-align: center;
            color: #707070;
        }
    }

    h5 {
        color: #707070;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .inlineButtons {
        width: 100%;
        margin-bottom: 22px;

        .infoButton {
            background-color: #EBEBEB;
            color: #707070;
            padding: 10px 12px;
            text-decoration: none;
            margin-right: 12px;

            svg {
                fill: #707070;
                width: 16px;
                height: auto;
                margin-right: 4px;
            }
        }

        @media screen and (max-width: 373px) {
            .infoButton {
                padding: 10px 10px;
                margin-right: 8px;

                svg {
                    margin-right: 2px;
                }
            }
        }

        @media screen and (max-width: 359px) {
            .infoButton {
                font-size: 14px;

                svg {
                    width: 14px;
                }
            }
        }
    }

    .highlight {
        color: #00D2ED;
        font-weight: 600;
        margin-right: 4px;
        font-size: 16px;
    }
}
</style>
