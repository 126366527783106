<template>
    <div>
        <div class="screenHeight">
            <section class="checkpoints">
                <div class="container bottomSpace">
                    <h2 class="pt-4 text-center" v-if='hasUserLocation'>Locations in the range of 200km</h2>
                    <h2 class="pt-4 text-center" v-if='!hasUserLocation'>All locations</h2>
                    <div class="pt-3 pb-3" v-for="closeLocation in closeLocations" :key="closeLocation.id">
                        <location-item :closeLocation="closeLocation" @openLocation="openLocation"></location-item>
                    </div>
                </div>

                <mobile-navigation></mobile-navigation>
            </section>

        </div>
    </div>
</template>

<script>
import MobileNavigation from '@/components/MobileNavigation';
import LocationItem from '@/components/LocationItem';
import Road from '@/models/Road';
import mapsLoader from '../services/gMapsLoader';

export default {
    data() {
        return {
            closeLocations: [],
        }
    },
    mounted() {
        this.setUpCheckpoints();
    },
    computed: {
        hasUserLocation() {
            return this.$store.getters.getLocation;
        }
    },
    methods: {
        async setUpCheckpoints() {
            const checkpoints = await Road.all();
            const google = await mapsLoader();

            if (this.hasUserLocation) {
                const userLat = this.$store.getters.getLocation.lat;
                const userLong = this.$store.getters.getLocation.lng;

                this.closeLocations = checkpoints.filter(function (checkpoint) {
                    const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(userLat, userLong), new google.maps.LatLng(checkpoint.lat, checkpoint.long));
                    return distance < 200000;
                });
            } else {
                this.closeLocations = checkpoints;
            }

            if (this.closeLocations.length < 1) {
                this.closeLocations = checkpoints;
            }
        },
        openLocation(id) {
            this.$store.dispatch('setSelectedRoad', id);
            this.$store.dispatch('setIsRoadSelected', true);

            this.$router.push({
                name: 'Home',
            })
        }
    },
    components: {
        MobileNavigation,
        LocationItem
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.screenHeight {
    height: 100%;
    min-height: 600px;

    .checkpoints {
        width: 100%;
        z-index: 100;
        background-color: white;

        .bottomSpace {
            padding-bottom: 100px;

            @media screen and (min-width: 768px) {
                margin-left: 80px;
                padding-right: 40px;
            }
        }

        .seeMap {
            position: fixed;
            right: 12px;
            bottom: 85px;
            background-color: white;
            padding: 8px 12px;
            text-decoration: none;
            color: #707070;
            box-shadow: 0px 3px 6px #e8e8e8;
        }
    }
}
</style>
